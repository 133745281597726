import {
  DashboardDailyApplicants,
  DashboardOpenJobPosts,
  DashboardToDoPosts
} from '../../components/ShowcaseComponents';
import useLocales from '../../hooks/useLocales';
import useAuth from '../../hooks/useAuth';
import Page from '../../components/containers/Page';
import TabPanel from '../../components/TabPanel';
import DashboardNavbar from '../../layouts/dashboard/navbar/DashboardNavbar';
import PageContainer from '../../components/containers/PageContainer';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getShowcaseData } from 'redux/slices/showcase';
import { RootState } from 'redux/store';
import Subscription from 'components/subscription/Subscription';

const addPost = '/static/img/dashboard/addPost.svg';
const addPostActive = '/static/img/dashboard/addPostActive.svg';
const application = '/static/img/dashboard/formApp.svg';
const applicationActive = '/static/img/dashboard/formActive.svg';
const checkListTodo = '/static/img/dashboard/checklist.svg';
const checkListTodoActive = '/static/img/dashboard/checklistActive.svg';

export default function Dashboard() {
  const { t } = useLocales();
  const { user } = useAuth();
  const { role } = user;
  const company: any = useSelector(
    (state: RootState) => state.generalRecruiter.company
  );
  const dispatch = useDispatch();

  const isAdmin: boolean = role === 'admin';
  const isShowSubscribe: boolean = isAdmin && !company?.onboarding_completed && company?.billing_email;

  useEffect(() => {
    dispatch(getShowcaseData());
  }, [dispatch]);

  const tabs = [
    {
      label: t('Open Job Posts'),
      Component: <DashboardOpenJobPosts />,
      icon: addPost,
      activeIcon: addPostActive,
      hide: !isAdmin,
      path: 'job-posts'
    },
    {
      label: t('New Applicants'),
      Component: <DashboardDailyApplicants />,
      icon: application,
      activeIcon: applicationActive,
      hide: !isAdmin,
      path: 'new-applicants'
    },
    {
      label: t('My To Do’s'),
      Component: <DashboardToDoPosts />,
      icon: checkListTodo,
      activeIcon: checkListTodoActive,
      hide: !isAdmin,
      path: 'todos'
    }
  ];

  const title = `${t('Welcome back')} ${user.name}`;

  return (
    <Page title={title}>
      {isShowSubscribe && <Subscription />}
      <PageContainer>
        <DashboardNavbar title={title} />
        <TabPanel tabs={tabs} dashboard={true} />
      </PageContainer>
    </Page>
  );
}
